import { REGISTER_CONFIG } from '~/constants/register'

const getIndex = (path: string) =>
  REGISTER_CONFIG.findIndex((step) => step.route === path)

export const getPreviousRegisterStepByPath = (path: string) =>
  REGISTER_CONFIG[getIndex(path) - 1]

export const getRegisterStepByPath = (path: string) =>
  REGISTER_CONFIG[getIndex(path)]

export const getNextRegisterStepByPath = (path: string) =>
  REGISTER_CONFIG[getIndex(path) + 1]
